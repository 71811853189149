import { LocaleEnum } from "shared/enums/locale-enum"

export const getCalculatedLanguage = (language: string) => {
    let readableLanguage
    switch (language) {
      case LocaleEnum.JAPANESE:
        readableLanguage = 'jp'
        break
      default:
        readableLanguage = language
    }
  
    return readableLanguage.toUpperCase()
  }