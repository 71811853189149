import { useCallback, useEffect, useRef } from 'react'

type SwipeDirection = 'left' | 'right' | 'up' | 'down'

interface UseOnSwipeParams {
  direction: SwipeDirection
  onSwipe: () => void
}

interface Touch {
  startX: number
  startY: number
}

export function useOnSwipe({ direction, onSwipe }: UseOnSwipeParams) {
  const touchRef = useRef<Touch>()

  const handleTouchStart = useCallback((e: TouchEvent) => {
    if (!e.changedTouches?.length) return
    if (!touchRef.current) touchRef.current = {} as Touch
    touchRef.current.startX = e.changedTouches[0].clientX
    touchRef.current.startY = e.changedTouches[0].clientY
  }, [])

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      if (!touchRef.current) return
      if (!e.changedTouches?.length) return

      switch (direction) {
        case 'left':
          return touchRef.current.startX < e.changedTouches[0].clientX && onSwipe()
        case 'right':
          return touchRef.current.startX > e.changedTouches[0].clientX && onSwipe()
        case 'up':
          return touchRef.current.startY < e.changedTouches[0].clientY && onSwipe()
        case 'down':
          return touchRef.current.startY > e.changedTouches[0].clientY && onSwipe()
      }
    },
    [onSwipe, direction],
  )

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [handleTouchEnd, handleTouchStart])
}
