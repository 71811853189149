import { PropsWithChildren, ReactNode, forwardRef, useCallback } from 'react'
import { twJoin } from 'tailwind-merge'
import { useOnSwipe } from 'shared/hooks/use-on-swipe'
import { useToggle } from 'shared/hooks/use-toggle'
import CloseIcon from 'shared/icons/close-icon'
import CollapseIcon from '../../icons/collapse-icon'
import { DrawerPositionEnum } from './types'

type DrawerProps = PropsWithChildren & {
  position?: DrawerPositionEnum
  open: boolean
  collapsible?: boolean
  title?: ReactNode
  onClose?: () => void
  controlled?: boolean
}

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(function Drawer(
  {
    position = DrawerPositionEnum.Right,
    open = false,
    collapsible = false,
    children,
    title,
    onClose,
    controlled,
  },
  ref,
) {
  const [collapsed, toggleCollapsed, setCollapsed] = useToggle()

  const TitleWrapper = typeof title === 'string' ? 'h2' : 'div'

  const handleSwipeLeft = useCallback(() => collapsible && setCollapsed(true), [collapsible])

  const handleSwipeRight = useCallback(() => collapsible && setCollapsed(false), [collapsible])

  useOnSwipe({ direction: 'left', onSwipe: handleSwipeLeft })
  useOnSwipe({ direction: 'right', onSwipe: handleSwipeRight })

  return (
    <div
      ref={ref}
      className={twJoin(
        'fixed bottom-0 h-full w-0 bg-white transition-all',
        position === DrawerPositionEnum.Right ? 'right-0' : 'left-0',
        open && !collapsed && 'w-80 md:w-96',
      )}
    >
      {collapsible && open && (
        <button
          className={twJoin(
            'absolute top-[50%] translate-y-[-50%]',
            position === DrawerPositionEnum.Right ? '-left-5' : '-right-5 rotate-180',
          )}
          onClick={toggleCollapsed}
        >
          <CollapseIcon />
        </button>
      )}
      <div className="customScroll flex h-full flex-col gap-5 overflow-y-auto overflow-x-hidden p-5">
        <div className="grid w-full grid-cols-[1fr,10%]">
          {title && <TitleWrapper>{title}</TitleWrapper>}
          {!controlled && (
            <button onClick={onClose} className="justify-self-end">
              <CloseIcon className="h-5 w-5 fill-darkblue" />
            </button>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
})

export default Drawer
