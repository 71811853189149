import { UserRolesEnum } from 'shared/enums/user-roles-enum'
import { isAuthorizedUser, isDomainOwner } from 'shared/hooks/use-user'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'

export const getShowDashboardLink = (user: undefined | UserInterface | UnauthorizedUser) =>
  (isAuthorizedUser(user) &&
    (isDomainOwner(user) || !isCustomDomain()) &&
    (user.roles.includes(UserRolesEnum.Customer) ||
      user.roles.includes(UserRolesEnum.Affiliate))) ||
  false

export const getDashboardLink = (user: undefined | UserInterface | UnauthorizedUser) => {
  return getShowDashboardLink(user)
    ? `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${
        process.env.NEXT_PUBLIC_SIO_HOSTNAME
      }/dashboard${
        (user as UserInterface).roles.includes(UserRolesEnum.Customer) ? '' : '/affiliate-dashboard'
      }`
    : ''
}

export const getDashboardNotificationSettingsLink = (user?: UserInterface | UnauthorizedUser) => {
  return `${getDashboardLink(user)}/profile/notification-settings`
}
