import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { httpClient } from '../../../shared/api/http-client'
import Confirmation from 'shared/components/confirmation-modal'
import { NotFound } from 'shared/errors/not-found'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser, { isDomainOwner, isWsAdmin, isWsAssistant } from 'shared/hooks/use-user'
import HomeIcon from 'shared/icons/home-icon'
import MembersIcon from 'shared/icons/members-icon'
import PostsIcon from 'shared/icons/posts-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import ThreeDotsNavIcon from 'shared/icons/three-dots-nav-icon'
import { useIsActive } from '../../header/hooks/use-is-active'
import { useNeedShowJoin } from '../context/join-community-provider'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import useIsUserMember from '../hooks/use-is-user-member'
import { TopicInterface } from '../types/topic-interface'
import AddTopic from './add-topic'
import SidebarJoin from './sidebar-join'
import UpdateTopic from './update-topic'

function Sidebar() {
  const router = useRouter()
  const { communityPath, topicPath } = router.query
  const { t } = useTranslation()
  const needShowStickyJoin = useNeedShowJoin()
  const { isUserMember } = useIsUserMember()
  const { user } = useUser()
  const [editableTopic, setEditableTopic] = useState<TopicInterface | null>(null)
  const [removableTopicId, setRemovableTopicId] = useState<number | null>(null)
  const { data: community, isValidating, error, mutate } = useCommunityWithTopics()
  const [topics, setTopics] = useState<TopicInterface[]>([])
  const selectedTopic = topics.find(topic => topic.path === topicPath)
  const { show } = useFloatHover()

  useEffect(() => {
    if (error instanceof NotFound) {
      router.replace('/404')
    }
  }, [error])

  useEffect(() => {
    if (community) {
      setTopics(community.topics)
    }
  }, [community])

  function resetEditableTopic() {
    setEditableTopic(null)
  }

  function resetRemovableTopic() {
    setRemovableTopicId(null)
  }

  async function removeTopic() {
    if (!community || !removableTopicId) {
      return
    }
    await httpClient.delete<TopicInterface>(`/api/community/topic/${removableTopicId}`)
    const newTopics = topics.filter(t => t.id !== removableTopicId)
    setTopics(topics.filter(t => t.id !== removableTopicId))
    setRemovableTopicId(null)
    await mutate(
      {
        ...community,
        topics: newTopics,
      },
      false,
    )
  }

  if (!community || isValidating || !user) {
    return <SidebarLoader />
  }

  const needShowSidebarJoin = !community.private && !isUserMember

  return (
    <aside className={`sticky top-[6.5rem] hidden h-fit w-full max-w-[248px] lg:block`}>
      {needShowSidebarJoin && <SidebarJoin />}
      <nav className="mb-6">
        <ul className="overflow-hidden rounded-lg bg-white">
          <MainNavItem
            href={`/community/${communityPath}`}
            title={t('sidebar.nav.main.home')}
            key="sidebar-nav-home"
          >
            <HomeIcon />
          </MainNavItem>
          <MainNavItem
            href={`/community/${communityPath}/members`}
            title={t('sidebar.nav.main.members')}
            key="sidebar-nav-members"
          >
            <MembersIcon />
          </MainNavItem>
          {(isDomainOwner(user) || isWsAdmin(user)) && (
            <>
              <MainNavItem
                href={`/community/${communityPath}/settings`}
                title={t('sidebar.nav.main.settings')}
                key="sidebar-nav-settings"
              >
                <SettingsIcon />
              </MainNavItem>
              {!community.autoPostsApproval && (
                <MainNavItem
                  href={`/community/${communityPath}/pending-posts`}
                  title={t('sidebar.nav.main.pending_posts')}
                  key="sidebar-nav-pending-posts"
                >
                  <PostsIcon />
                </MainNavItem>
              )}
            </>
          )}
          {!community.autoPostsApproval &&
            isUserMember &&
            !isDomainOwner(user) &&
            !isWsAdmin(user) && (
              <MainNavItem
                href={`/community/${communityPath}/pending-posts`}
                title={t('sidebar.nav.main.pending_posts')}
                key="sidebar-nav-members-pending-posts"
              >
                <PostsIcon />
              </MainNavItem>
            )}
        </ul>
      </nav>
      <nav>
        <ul className="rounded-lg bg-white">
          <li className="bg-lightblue/40 px-4 py-3.5">
            <h5 className="flex items-center gap-2 text-sm uppercase text-darkblue">
              {t('sidebar.topics.title')}{' '}
              {(isDomainOwner(user) || isWsAdmin(user) || isWsAssistant(user)) && <AddTopic />}
            </h5>
          </li>
          <li className="px-4 py-3.5">
            <ul className={`max-h-80 overflow-auto`}>
              {topics.map(topic => (
                <li className="group mx-1 my-1 flex items-center justify-between" key={topic.path}>
                  <Link
                    className={`${
                      selectedTopic?.id === topic.id ? 'text-blue' : 'text-darkblue/80'
                    }`}
                    shallow
                    href={`/community/${communityPath}/${topic.path}`}
                  >
                    #{topic.name}
                  </Link>
                  {(isDomainOwner(user) || isWsAdmin(user) || isWsAssistant(user)) && (
                    <Menu
                      as="div"
                      className="invisible relative flex items-center group-hover:visible"
                    >
                      <MenuButton className="flex items-center">
                        <ThreeDotsNavIcon className="text-darkblue/80 hover:text-darkblue" />
                      </MenuButton>
                      <Transition
                        enter="transition duration-202 ease-out"
                        enterFrom="opacity-2"
                        enterTo="opacity-102"
                        leave="transition duration-77 ease-in"
                        leaveFrom="opacity-102"
                        leaveTo="opacity-2"
                      >
                        <MenuItems
                          anchor="bottom start"
                          className="right-0 top-0 z-10 w-36 origin-center rounded-md bg-lightblue shadow-lg"
                        >
                          <MenuItem as="div" className="px-1 py-1">
                            {({ focus }) => (
                              <button
                                className={`${
                                  focus ? 'text-darkblue' : 'text-darkblue/40'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                onClick={() => setEditableTopic(topic)}
                              >
                                {t('settings.form.update.label')}
                              </button>
                            )}
                          </MenuItem>
                          <MenuItem as="div" className="px-1 py-1">
                            {({ focus }) => (
                              <button
                                className={`${
                                  focus ? 'text-darkblue' : 'text-darkblue/40'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                onClick={() => setRemovableTopicId(topic.id)}
                              >
                                {t('settings.form.delete.label')}
                              </button>
                            )}
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  )}
                </li>
              ))}
            </ul>
          </li>
          {/*<li className="px-4 py-3.5">*/}
          {/*  <Disclosure>*/}
          {/*    {({ open }) => (*/}
          {/*      <>*/}
          {/*        <Disclosure.Button className="flex w-full items-center justify-between font-medium">*/}
          {/*          <span>💬 General Discussion</span>{' '}*/}
          {/*          <ChevronUpIcon*/}
          {/*            className={`transition-transform ${open ? 'rotate-180 transform' : ''}`}*/}
          {/*          />*/}
          {/*        </Disclosure.Button>*/}
          {/*        <Disclosure.Panel className="transition-all">*/}
          {/*          <ul>*/}
          {/*            <li className="mx-1 my-3">#Lorem Ipsum</li>*/}
          {/*            <li className="mx-1 my-3">#Lorem Ipsum</li>*/}
          {/*          </ul>*/}
          {/*        </Disclosure.Panel>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  </Disclosure>*/}
          {/*</li>*/}
        </ul>
      </nav>
      {editableTopic && (
        <UpdateTopic
          topic={editableTopic}
          onCloseModal={resetEditableTopic}
          onUpdateSuccess={resetEditableTopic}
        />
      )}
      {removableTopicId && (
        <Confirmation
          onConfirm={removeTopic}
          onCloseModal={resetRemovableTopic}
          confirmationContent={t('settings.form.delete_topic_confirmation.label')}
        />
      )}
    </aside>
  )
}

export function SidebarLoader() {
  return (
    <aside className="sticky top-6 hidden w-[248px] lg:block">
      <div className="animate-pulse">
        <div className="mb-12">
          <div className="mb-4 h-6 w-28 rounded-sm bg-gray" />
          <div className="h-6 w-28 rounded-sm bg-gray" />
        </div>

        <div className="mb-10">
          <div className="mb-6 h-5 w-32 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
        </div>

        <div>
          <div className="mb-6 h-5 w-32 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
          <div className="mb-3 h-4 rounded-sm bg-gray" />
        </div>
      </div>
    </aside>
  )
}

type MainNavItemProps = {
  href: string
  title: string
}

function MainNavItem({ href, children, title }: PropsWithChildren<MainNavItemProps>) {
  const isActive = useIsActive(href)
  return (
    <li className="group text-darkblue hover:text-blue">
      <Link
        className={`flex items-center border-l-2 py-3.5 pl-4 hover:border-l-blue hover:bg-lightblue/40 ${
          isActive ? 'border-l-blue bg-lightblue/40 text-blue' : 'border-l-transparent'
        }`}
        href={href}
        shallow
      >
        <div
          className={`mr-4 ${isActive ? 'text-blue' : 'text-darkblue/30'} group-hover:text-blue`}
        >
          {children}
        </div>
        {title}
      </Link>
    </li>
  )
}

export default Sidebar
