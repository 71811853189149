import { StepType } from '../hooks/use-step-router/types'
import { CourseMenuModuleInterface } from '../types/course-menu-interface'
import { CourseStepEnum } from '../types/course-step-type'

export const mapMenuToSteps = (courseMenu: CourseMenuModuleInterface[] | undefined) => {
  if (!courseMenu) return []

  const stepsArray: StepType[] = []

  for (let menuModule of courseMenu) {
    for (let lecture of menuModule.lectures) {
      stepsArray.push({
        module: {
          name: menuModule.name,
          available: menuModule.available,
          salesPage: menuModule.salesPage,
        },
        type: CourseStepEnum.lecture,
        ...lecture,
      })
    }
    if (menuModule.quiz) {
      stepsArray.push({
        module: {
          name: menuModule.name,
          available: menuModule.available,
          salesPage: menuModule.salesPage,
        },
        type: CourseStepEnum.quiz,
        ...menuModule.quiz,
      })
    }
  }

  return stepsArray
}

export const findStep = (courseSteps: StepType[]) => {
  const availableSteps = courseSteps.filter(({ available }) => available)
  const uncompletedAvailableSteps = availableSteps.filter(({ completed }) => !completed)

  let stepIndex: number | null = null
  if (uncompletedAvailableSteps.length) {
    stepIndex = courseSteps.findIndex(({ id }) => id === uncompletedAvailableSteps[0].id)
  } else if (availableSteps.length) {
    stepIndex = courseSteps.findIndex(({ id }) => id === availableSteps[0].id)
  } else if (courseSteps.length) {
    stepIndex = 0
  }

  return typeof stepIndex === 'number' && courseSteps[stepIndex]
    ? ({
        ...courseSteps[stepIndex],
      } as StepType)
    : undefined
}

export const withModuleAvailability = (modules: CourseMenuModuleInterface[]) => {
  const modulesWithAvailability = modules.map(module => {
    const isAvailable = module.lectures.some(l => l.available)
    const isCompleted =
      module.lectures.every(lecture => lecture.completed) && (!module.quiz || module.quiz.completed)
    return {
      ...module,
      available: isAvailable,
      isCompleted,
      shouldShowQuiz: module.lectures.length > 0 && isAvailable,
    }
  })

  return modulesWithAvailability
}
