import React, { cloneElement } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { useIsActive } from 'modules/header/hooks/use-is-active'

type TopMenuItemProps = {
  icon: React.ReactElement
  name: string
  href: string
  ignoreBasePath?: boolean
  className?: string
  activeClassName?: string
}

export function TopMenuItem({
  icon,
  name,
  href,
  ignoreBasePath = false,
  className,
  activeClassName,
  ...rest
}: TopMenuItemProps) {
  const isActive = useIsActive(href)
  const { t } = useTranslation()

  const Icon = cloneElement(icon, {
    ...icon.props,
    className: twMerge(icon.props.className, 'max-w-10 max-h-10'),
  })

  return React.createElement(
    ignoreBasePath ? 'a' : Link,
    {
      href: href,
      className: twMerge(
        `hidden lg:flex items-center border-b-4 border-transparent gap-2 text-gray-200 hover:text-white h-full text-sm font-bold`,
        className,
        isActive && twMerge(`text-white border-blue`, activeClassName),
      ),
      ...rest,
    },
    <>
      {Icon}
      <span className="mt-1">{t(name)}</span>
    </>,
  )
}
