import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import PaletteIcon from 'shared/icons/palette-icon'
import useThemeEditorToggle from '../hooks/use-theme-editor-toggle'

export default function ThemeEditorButton({ className }: { className?: string }) {
  const { t } = useTranslation()

  const { openEditor, isOpenEditor, allowedToEdit } = useThemeEditorToggle()

  if (!allowedToEdit || isOpenEditor) return null

  return (
    <button
      title={t('themes.editor.cta.manage_themes')}
      onClick={openEditor}
      className={twMerge(
        'group flex items-center justify-center whitespace-nowrap rounded-lg bg-blue p-2.5 text-sm font-semibold text-white transition-colors hover:bg-blue-300 hover:text-white',
        className,
      )}
    >
      <PaletteIcon aria-hidden className="group-hover:text-THEME-hover-header-text lg:hidden" />
      <span className="sr-only lg:not-sr-only">{t('themes.editor.cta.manage_themes')}</span>
    </button>
  )
}
