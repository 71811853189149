import { ComponentProps } from 'react'

export default function PaletteIcon(props: ComponentProps<'svg'>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_62312_9870)">
        <path d="M18.7179 15.3385C17.9663 15.339 17.2141 15.3231 16.4631 15.3424C14.6839 15.3879 13.6602 16.7681 14.1675 18.4787C14.4118 19.3018 14.8265 20.0783 15.0316 20.9088C15.4906 22.7706 14.1766 24.3697 12.2599 24.3679C5.80468 24.3623 0.58395 19.445 0.192537 13.0004C-0.190354 6.70917 4.43786 1.24509 10.8186 0.45549C17.2755 -0.34434 23.2234 4.44668 24.1039 10.8675C24.5527 14.139 22.6667 15.5818 20.0534 15.3436C19.6115 15.3032 19.1633 15.3379 18.7179 15.3385ZM12.1707 3.30293C11.2901 3.30122 10.659 3.93961 10.6573 4.8338C10.6556 5.73993 11.2663 6.35047 12.1707 6.34819C13.0609 6.34592 13.7034 5.71038 13.7017 4.83323C13.7 3.97031 13.0336 3.30463 12.1707 3.30293ZM6.17734 6.31295C5.29624 6.2993 4.65316 6.9303 4.64123 7.82052C4.62873 8.72835 5.23091 9.34968 6.13189 9.35821C7.02493 9.36674 7.67539 8.742 7.68561 7.86599C7.69584 7.00363 7.03856 6.32602 6.17734 6.31295ZM18.2083 6.31295C17.3272 6.29987 16.6853 6.932 16.6739 7.82279C16.6625 8.73063 17.2653 9.35082 18.1663 9.35821C19.0587 9.3656 19.7086 8.74029 19.7183 7.86429C19.7274 7.00193 19.0695 6.32545 18.2083 6.31295ZM4.65998 12.3336C3.77887 12.3267 3.14318 12.9623 3.13694 13.8548C3.13069 14.7615 3.7374 15.3765 4.64123 15.3788C5.53256 15.3811 6.17848 14.7501 6.18189 13.8735C6.18529 13.0106 5.5229 12.3404 4.65998 12.3336Z" />
      </g>
      <defs>
        <clipPath id="clip0_62312_9870">
          <rect width="24" height="24" transform="translate(0.169434 0.367676)" />
        </clipPath>
      </defs>
    </svg>
  )
}
