import { useCallback, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { twJoin, twMerge } from 'tailwind-merge'
import Drawer from 'shared/components/drawer'
import { usePlainClickOutside } from 'shared/hooks/use-click-outside'
import { useToggle } from 'shared/hooks/use-toggle'
import CapIcon from 'shared/icons/cap-icon'
import CloseIcon from 'shared/icons/close-icon'
import { CourseSideMenu } from '../course-side-menu'

interface MobileCourseSideMenuProps {
  className?: string
}

export default function MobileCourseSideMenu({ className }: MobileCourseSideMenuProps) {
  const { t } = useTranslation()
  const [isOpenModuleList, toggle, setIsOpenModuleList] = useToggle()
  const buttonRef = useRef(null)
  const drawerRef = useRef(null)

  let Icon = CapIcon
  let label = 'course.show_curriculum'

  if (isOpenModuleList) {
    Icon = CloseIcon
    label = 'course.hide_curriculum'
  }

  const handleClickOutside = useCallback(() => setIsOpenModuleList(false), [])

  usePlainClickOutside([buttonRef, drawerRef], handleClickOutside)

  return (
    <>
      <button
        ref={buttonRef}
        onClick={toggle}
        className={twMerge(
          'flex h-16 w-16 items-center justify-center rounded-full bg-white p-3 shadow-[0_2px_20px_0_rgba(0,0,0,0.1)]',
          className,
        )}
        aria-label={t(label)}
      >
        <Icon
          className={twJoin(
            'pointer-events-none',
            isOpenModuleList ? 'h-6 w-6 text-darkblue' : 'text-blue',
          )}
        />
      </button>
      <Drawer ref={drawerRef} controlled open={isOpenModuleList}>
        <CourseSideMenu menuClassName="px-0" />
      </Drawer>
    </>
  )
}
