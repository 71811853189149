import React from 'react'
import { useTranslation } from 'next-i18next'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { dateTimeFormat } from 'modules/profile/utils/date-time-format'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

export const CourseAccessFromScreen = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { courseData } = useCourseData()

  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(`course__info-screen--course-will-be-available`)}
      text={t('course.course_will_be_available_message', {
        date:
          (isAuthorizedUser(user) &&
            courseData?.accessFrom &&
            dateTimeFormat(courseData.accessFrom, {
              timeFormat: user.timeFormat,
              dateFormat: user.dateFormat,
              timeZone: user.timezone,
            })) ||
          '',
      })}
    />
  )
}
