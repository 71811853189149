import { useMemo } from 'react'
import { mapMenuToSteps } from '../utils/course-steps'
import { useCourseData } from './use-course-data'
import { useCourseMenu } from './use-course-menu'

export default function useCourseSteps() {
  const { courseData } = useCourseData()
  const { courseMenu } = useCourseMenu({ courseId: courseData?.id })

  const courseSteps = useMemo(() => mapMenuToSteps(courseMenu), [courseMenu])

  return { courseSteps }
}
