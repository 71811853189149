import { useRollbar } from '@rollbar/react'
import { useEffect } from 'react'
import { i18n } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { DISABLED_PAGE_URL } from 'shared/constants/disabled-page-url'
import { UserRolesEnum } from 'shared/enums/user-roles-enum'
import { AccessDenied } from 'shared/errors/access-denied'
import { UnauthorizedUser, UserInterface } from '../types/user-interface'
import { useApiWrapperWithErrorValidation } from './use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from './use-api-wrapper-with-error-validation/types'

export function isAuthorizedUser(user?: UserInterface | UnauthorizedUser): user is UserInterface {
  return !!user && (user as UserInterface).id !== undefined
}

export function isNotDomainOwner(user: UserInterface | UnauthorizedUser) {
  return !isAuthorizedUser(user) || (isAuthorizedUser(user) && !user.isDomainOwner)
}

export function isDomainOwner(user: UserInterface | UnauthorizedUser) {
  return isAuthorizedUser(user) && user.isDomainOwner
}

export function isCustomer(user: UserInterface | UnauthorizedUser) {
  return (isAuthorizedUser(user) && user?.roles.includes(UserRolesEnum.Customer)) || false
}

export function isAffiliate(user: UserInterface | UnauthorizedUser) {
  return (isAuthorizedUser(user) && user?.roles.includes(UserRolesEnum.Affiliate)) || false
}

export function isWorkspaceOwner(user?: UserInterface | UnauthorizedUser) {
  return !!user && isAuthorizedUser(user) ? user.workspaceOwner === null : false
}

export function isWsAssistant(user?: UserInterface | UnauthorizedUser) {
  if (user && isAuthorizedUser(user) && !isWorkspaceOwner(user) && user) {
    const workspace = user.workspaceMemberships?.find(el => el.ownerId === user.workspaceOwner)
    return workspace ? workspace.role === 'role_assistant' : false
  } else {
    return false
  }
}

export function isWsAdmin(user?: UserInterface | UnauthorizedUser) {
  if (user && isAuthorizedUser(user) && !isWorkspaceOwner(user) && user) {
    const workspace = user.workspaceMemberships?.find(el => el.ownerId === user.workspaceOwner)
    return workspace ? workspace.role === 'role_admin' : false
  } else {
    return false
  }
}

export function isWorkspaceMember(user: UserInterface | UnauthorizedUser) {
  return isAuthorizedUser(user) && (isDomainOwner(user) || isWsAdmin(user) || isWsAssistant(user))
}

export const USER_DATA_API = '/api/user/user-data'

export default function useUser() {
  const router = useRouter()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    UserInterface | UnauthorizedUser
  >({ method: RequestMethodsEnum.get })
  const { data, mutate, isValidating } = useSWR(USER_DATA_API, fetcher, {
    onError(err) {
      if (err instanceof AccessDenied) {
        router.push(DISABLED_PAGE_URL)
      }
    },
  })

  const rollbar = useRollbar()

  useEffect(() => {
    if (data) {
      const isLocaleInUrl = window.location.pathname.startsWith(`/${router.locale}/`)
      const locale = isLocaleInUrl ? router.locale : data.dashboardLocale
      i18n?.changeLanguage(locale)
      if (isAuthorizedUser(data)) {
        rollbar.configure({
          payload: {
            person: {
              id: data.id,
              username: `${data.firstName} ${data.lastName}`,
            },
          },
        })
      }
    }
  }, [data, router])

  return {
    loading: isValidating,
    user: data,
    mutate,
    isOwner: data && isDomainOwner(data),
  }
}
