import { RefObject, useEffect, useRef } from 'react'

export function usePlainClickOutside<T extends HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  cb?: () => void,
) {
  useEffect(() => {
    const handleClickOutside = (evt: MouseEvent) => {
      const isOutside = Array.isArray(ref)
        ? ref.every(r => r.current && !r.current?.contains(evt.target as Node))
        : !ref.current?.contains(evt.target as Node)
      isOutside && cb?.()
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [cb])
}

const DEFAULT_EVENTS = ['mousedown', 'touchstart']

export function useClickOutside<T extends HTMLElement = any>(
  handler: () => void,
  events?: string[] | null,
  nodes?: (HTMLElement | null)[],
) {
  const ref = useRef<T>()

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {}
      if (Array.isArray(nodes)) {
        const shouldIgnore =
          target?.hasAttribute('data-ignore-outside-clicks') ||
          (!document.body.contains(target) && target.tagName !== 'HTML')
        const shouldTrigger = nodes.every(node => !!node && !event.composedPath().includes(node))
        shouldTrigger && !shouldIgnore && handler()
      } else if (ref.current && !ref.current.contains(target)) {
        handler()
      }
    }

    ;(events || DEFAULT_EVENTS).forEach(fn => document.addEventListener(fn, listener))

    return () => {
      ;(events || DEFAULT_EVENTS).forEach(fn => document.removeEventListener(fn, listener))
    }
  }, [ref, handler, nodes])

  return ref
}
