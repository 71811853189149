import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import PrimaryButton from 'shared/components/primary-button'
import { AccessDenied } from 'shared/errors/access-denied'
import { UnauthorizedResponse } from 'shared/errors/unauthorized-response'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import CloseCircleIcon from 'shared/icons/close-circle-icon'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import useIsUserMember from '../hooks/use-is-user-member'
import { useJoinCommunity } from '../hooks/use-join-community'
import useMembersCount from '../hooks/use-members-count'
import { CommunityInterface } from '../types/community-interface'

function StickyJoin({ closeJoin }: { closeJoin: () => void }) {
  const router = useRouter()
  const { t } = useTranslation()
  const communityPath = router.query.communityPath as CommunityInterface['path']
  const { membersCount } = useMembersCount()
  const { mutate, isUserMember } = useIsUserMember()
  const { user } = useUser()

  const { data: community, isValidating: isCommunityLoading } = useCommunityWithTopics()
  const { joinCommunity } = useJoinCommunity()

  async function join() {
    try {
      if (isAuthorizedUser(user)) {
        await joinCommunity(communityPath)
        await mutate()
        closeJoin()
      } else {
        // the public community has an own login form, and we need to make custom redirection
        await router.replace(`/community/${communityPath}/join`)
      }
    } catch (e) {
      if (e instanceof UnauthorizedResponse) {
        await router.replace(`/community/${communityPath}/join`)
      } else if (e instanceof AccessDenied) {
        await router.replace(`/community/403`)
      }
    }
  }

  if (community && community.private) {
    return <div />
  }

  if (isCommunityLoading || !community) {
    return (
      <div className="sticky top-[69px] bg-white shadow-sm">
        <div className="mt-5 h-6 w-6 animate-pulse rounded-sm bg-gray" />
      </div>
    )
  }

  return (
    <div className="bg-white p-5 shadow-sm lg:p-0">
      <div className="mx-auto flex max-w-8xl items-center justify-between px-0 sm:px-2 md:px-5 lg:py-3.5">
        <div>
          <div className="text-lg font-semibold text-darkblue lg:text-xl">
            <Trans
              i18nKey="join_form.title"
              values={{ name: community.name }}
              components={{
                strong: <strong />,
                href: <a href={`/community/${communityPath}`} className="underline" />,
              }}
            />
          </div>
          <div className="text-gray-400">
            {t('sticky-join.members_count', { count: membersCount })}
          </div>
        </div>
        <div className="flex items-center gap-5">
          <PrimaryButton onClick={join}>{t('join_form.button.join.title')}</PrimaryButton>
          <button onClick={closeJoin}>
            <CloseCircleIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default StickyJoin
