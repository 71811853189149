import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export function useToggle(initialValue?: boolean) {
  const [value, setValue] = useState(initialValue ?? false)

  const toggle = useCallback(() => setValue(val => !val), [])

  return [value, toggle, setValue] as [
    value: boolean,
    toggle: () => void,
    setValue: Dispatch<SetStateAction<boolean>>,
  ]
}
