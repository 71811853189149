export interface ProgressBarProps {
  className?: string
  indicatorClassName?: string
  progress: number
  theme?: 'light_blue' | 'dark_blue'
}

const getWrapperClassName = ({ theme }: Pick<ProgressBarProps, 'theme'>) =>
  `relative text-sm leading-[22px] text-center font-semibold w-full h-[24px] overflow-hidden rounded-xl border-[0.7px] ${(() => {
    switch (theme) {
      case 'dark_blue':
        return 'text-darkblue bg-white border-darkblue/30'
      case 'light_blue':
        return 'text-blue bg-darkblue2 border-darkblue/30'
    }
  })()}`

const getProgressIndicatorClassName = ({ theme }: Pick<ProgressBarProps, 'theme'>) =>
  `absolute inset-0 overflow-hidden rounded-xl h-full
  ${(() => {
    switch (theme) {
      case 'dark_blue':
        return 'text-white bg-darkblue'
      case 'light_blue':
        return 'text-white bg-blue'
    }
  })()}`

const ProgressBar = ({
  className,
  indicatorClassName,
  progress,
  theme = 'dark_blue',
}: ProgressBarProps) => (
  <div
    className={`${getWrapperClassName({ theme })} ${className || ''}`}
    data-test-id="course-progress-bar"
  >
    <div
      className={`${getProgressIndicatorClassName({ theme })} ${indicatorClassName || ''}`}
      style={{ width: `${progress}%` }}
    >
      <div style={{ width: `${100 * (100 / progress)}%` }}>{progress}%</div>
    </div>
    {progress}%
  </div>
)

export default ProgressBar
