import { isAuthorizedUser, isWorkspaceOwner, isWsAdmin, isWsAssistant } from 'shared/hooks/use-user'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'
import { CourseDataInterface } from '../types/course-interface'

export const isAdminOrOwnerOfCourse = (
  user: UserInterface | UnauthorizedUser | undefined,
  courseData: CourseDataInterface | undefined,
) =>
  isAuthorizedUser(user) &&
  ((courseData?.owner.id === user.id && isWorkspaceOwner(user)) ||
    (courseData?.owner.id === user.workspaceOwner && (isWsAdmin(user) || isWsAssistant(user))))
