import { useRouter } from "next/router"
import { useAvailableSections } from "./use-available-sections"

export const useLogoUrl = () => {
  const router = useRouter()
  const { data: availableSections } = useAvailableSections()

  if (availableSections?.communities && availableSections.courses) {
    return router.route.includes('school') ? '/school' : '/community'
  }

  if (availableSections === undefined) return undefined

  if (
    availableSections?.courses ||
    (availableSections?.communities === undefined && availableSections?.courses === undefined)
  )
    return '/school'

  if (availableSections?.communities) return '/community'
}