import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { MEMBERSHIP_COURSE_API } from '../api/membership-api'
import { CourseId } from '../types/course-interface'
import { CourseMenuModuleInterface } from '../types/course-menu-interface'
import { withModuleAvailability } from '../utils/course-steps'

export interface UseCourseProps {
  courseId: CourseId | undefined
}

export function useCourseMenu({ courseId }: UseCourseProps) {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseMenuModuleInterface[]
  >({
    method: RequestMethodsEnum.get,
    afterFetch: withModuleAvailability,
  })

  const { data: courseMenu, mutate } = useSWR<CourseMenuModuleInterface[]>(
    () => user && courseId !== undefined && `${MEMBERSHIP_COURSE_API}/${courseId}/menu`,
    fetcher,
  )

  const areAllModulesUnavailable = courseMenu?.every(module => !module.available)

  return {
    courseMenu,
    mutate,
    areAllModulesUnavailable,
  }
}
