import { ChangeEventHandler } from 'react'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'
import FormInput, { InputProps } from './form-input'

type FormInputRhfProps<TValues extends FieldValues, TName extends FieldPath<TValues>> = Omit<
  InputProps,
  'value' | 'onChange'
> &
  Omit<ControllerProps<TValues, TName>, 'render'> & {
    setValue: UseFormSetValue<TValues>
    afterChange?: (value: PathValue<TValues, TName>) => void
  }

export default function FormInputRhf<
  TValues extends FieldValues,
  TName extends FieldPath<TValues>,
>({
  control,
  name,
  rules,
  setValue,
  afterChange,
  ...restProps
}: FormInputRhfProps<TValues, TName>) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    const typedValue = value as PathValue<TValues, TName>
    setValue(name, typedValue)
    afterChange?.(typedValue)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormInput {...restProps} name={name} value={field.value} onChange={handleChange} />
      )}
    />
  )
}
