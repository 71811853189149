import React from 'react'
import { useTranslation } from 'next-i18next'
import { twJoin } from 'tailwind-merge'
import { useShowChangePasswordPage } from 'shared/hooks/use-show-change-password-page'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import useUser, { isAuthorizedUser } from '../hooks/use-user'
import ChevronIcon from '../icons/chevron-icon'
import LinkWithoutPrefetch from './link-without-prefetch'

const hrefArray = ['/dashboard/profile/user-settings', '/dashboard/profile/change-password']

const Link = ({
  href,
  children,
  className,
  external,
  ...rest
}: React.PropsWithChildren<{ href: string; className?: string; external?: boolean }>) => {
  const LinkComponent = external ? 'a' : LinkWithoutPrefetch

  return (
    <LinkComponent
      href={href}
      className={twJoin('cursor-pointer px-7 py-1.5 hover:bg-lightblue', className)}
      {...rest}
    >
      {children}
    </LinkComponent>
  )
}

const addBorder = <T extends HTMLElement | null>(element: T) => {
  if (!element) return
  const pathName = window.location.pathname
  element.classList.add(hrefArray.includes(pathName) ? 'border-blue' : 'border-transparent')
}

function Avatar({
  popupClassName,
  linkClassName,
}: {
  popupClassName?: string
  linkClassName?: string
}) {
  const { user, loading } = useUser()
  const { t } = useTranslation()
  const { showChangePasswordPage } = useShowChangePasswordPage()

  if (user && !isAuthorizedUser(user)) {
    return null
  }

  if (loading || !user) {
    return (
      <div
        className={`h-10 w-10 animate-pulse rounded-full bg-gray ${addPublicCustomClassNamePrefix(
          'avatar-skeleton',
        )}`}
      />
    )
  }

  return (
    <div className={`h-full shrink-0 ${addPublicCustomClassNamePrefix('avatar')}`}>
      <div className="group relative h-full">
        <button
          ref={addBorder}
          className={`flex h-full items-center gap-2.5 border-b-4 focus:outline-none focus-visible:border-blue`}
        >
          <img
            className="h-10 w-10 rounded-full object-cover"
            src={user.avatarUrl}
            alt={`${user.firstName} ${user.lastName}`}
            data-test-id="header-avatar-icon"
          />
          <ChevronIcon />
        </button>
        <div
          className={`bg-grey-200 invisible absolute -left-[65px] top-[65px] z-50 min-w-max opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100`}
        >
          <div
            className={`absolute -top-[12px] right-[85px] border-[6px] border-b-white border-l-transparent border-r-transparent border-t-transparent`}
          />
          <div
            className={`flex flex-col rounded-lg bg-white py-4 text-sm text-gray-300 shadow-lg ${
              popupClassName || ''
            } `}
          >
            {!user.workspaceOwner && (
              <Link
                href="/profile/user-settings"
                className={linkClassName}
                data-test-id="header-profile-settings"
              >
                {t('user.profile.menu.settings')}
              </Link>
            )}
            {showChangePasswordPage && (
              <Link
                href="/profile/change-password"
                className={linkClassName}
                data-test-id="header-profile-change-password"
              >
                {t('user.profile.menu.change_password')}
              </Link>
            )}
            <hr className="mx-4 px-4 text-gray" />
            {user.impersonator && (
              <Link
                external
                href="/dashboard/admin/management/user/list?pretend_this_user=_exit"
                data-test-id="header-profile-exit-impersonation"
              >
                {t('header.exit_impersonation')}
              </Link>
            )}
            <Link
              external
              href="/dashboard/logout"
              className={linkClassName}
              data-test-id="header-profile-logout"
            >
              {t('user.profile.menu.logout')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Avatar
