import { ComponentProps } from 'react'

export default function CapIcon(props: ComponentProps<'svg'>) {
  return (
    <svg width="42" height="27" viewBox="0 0 42 27" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.137 6.52266L21.1178 0.0107155C21.0753 -0.00357183 21.0285 -0.00357183 20.9857 0.0107155L0.864319 6.42337C0.775716 6.45193 0.71534 6.53372 0.714368 6.62655C0.713387 6.7197 0.772134 6.80248 0.859765 6.83267L5.77578 8.51717V14.354C4.95039 14.7247 4.37495 15.552 4.37495 16.5154C4.37495 17.4787 4.95106 18.3067 5.77578 18.6768L4.4833 27H8.79175L7.71481 18.6768C8.54021 18.3064 9.11564 17.4787 9.11564 16.5154C9.11564 15.552 8.54017 14.7247 7.71481 14.354V9.18124L20.913 13.7036C20.9354 13.7114 20.9591 13.7153 20.9825 13.7153C21.0059 13.7153 21.0295 13.7117 21.052 13.7039L41.1393 6.93196C41.2276 6.9021 41.2863 6.81932 41.2857 6.72682C41.2847 6.63335 41.225 6.55155 41.137 6.52266H41.137Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9806 14.7931C20.8381 14.7931 20.6979 14.7691 20.5632 14.723L10.0488 11.1206V18.815C10.0488 20.9764 15.0158 22.7284 21.1432 22.7284C27.2705 22.7284 32.2375 20.9764 32.2375 18.815L32.2372 11.0703L21.3963 14.725C21.2629 14.7708 21.1223 14.7932 20.9805 14.7932L20.9806 14.7931Z"
        />
      </g>
    </svg>
  )
}
