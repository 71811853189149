import { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { twJoin } from 'tailwind-merge'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { useAvailableSections } from '../hooks/use-available-sections'
import { getDashboardLink, getShowDashboardLink } from '../menu/utils'
import { AvailableSectionsInterface } from '../types/available-sections-interface'

interface LogoLinkProps extends PropsWithChildren {
  logoSrc?: string
}

const getLogoUrl = (route: string, availableSections?: AvailableSectionsInterface) => {
  if (availableSections?.communities && availableSections.courses) {
    return route.includes('school') ? '/school' : '/community'
  }

  if (availableSections === undefined) return undefined

  if (
    availableSections.courses ||
    (availableSections.communities === undefined && availableSections.courses === undefined)
  )
    return '/school'

  if (availableSections.communities) return '/community'
}

const LogoLink = ({ children, logoSrc }: LogoLinkProps) => {
  const { user } = useUser()
  const shouldRenderDashboardLink = getShowDashboardLink(user)
  const dashboardLink = getDashboardLink(user)
  const router = useRouter()
  const shouldRenderBasic = shouldRenderDashboardLink && !isCustomDomain()
  const { data: availableSections } = useAvailableSections()
  const logoUrl = getLogoUrl(router.route, availableSections)

  if (shouldRenderBasic) {
    return (
      <a
        className={twJoin(!shouldRenderDashboardLink && 'pointer-events-none')}
        href={dashboardLink}
      >
        {children}
      </a>
    )
  }

  if (logoUrl === undefined) {
    return <img src={logoSrc} alt="Systeme.io" className="max-h-[35px]" />
  }

  return <LinkWithoutPrefetch href={logoUrl}>{children}</LinkWithoutPrefetch>
}

export default LogoLink
