import { useTranslation } from 'next-i18next'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import ClockIcon from 'shared/icons/clock-icon'
import RoundedLockIcon from 'shared/icons/rounded-lock-icon'
import useStepRouter from 'modules/course/hooks/use-step-router'
import type { CourseMenuLectureInterface } from 'modules/course/types/course-menu-interface'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'

interface CourseAvailabilityIconProps {
  isPostponed: boolean
  lectureId?: CourseMenuLectureInterface['id']
}

export default function CourseAvailabilityIcon({
  isPostponed,
  lectureId,
}: CourseAvailabilityIconProps) {
  const { t } = useTranslation()
  const { getLectureUrl } = useStepRouter()
  const LockIconWrapper = !isPostponed && lectureId ? LinkWithoutPrefetch : 'span'

  return (
    <span
      title={t(
        isPostponed
          ? 'course.menu.module.dripping_access_unavailable.tooltip'
          : 'course.menu.module.partial_access_unavailable.tooltip',
      )}
    >
      {isPostponed ? (
        <ClockIcon
          className={'h-[17px] text-THEME-disabled'}
          data-test-id={`course-menu-lecture-${lectureId}-clock-icon`}
        />
      ) : (
        <LockIconWrapper
          className={addPublicCustomClassNamePrefix('course-modules-list__module-sales-page-link')}
          href={getLectureUrl(lectureId!)}
          scroll={false}
          data-test-id={`course-menu-lecture-${lectureId}-lock-icon`}
        >
          <RoundedLockIcon className={'h-[15px] text-THEME-disabled'} />
        </LockIconWrapper>
      )}
    </span>
  )
}
