import useUser from 'shared/hooks/use-user'
import { isAdminOrOwnerOfCourse } from '../utils/course-user-role'
import { useCourseData } from './use-course-data'

export const useIsUserAdminOrOwnerOfCourse = () => {
  const { user } = useUser()
  const { courseData } = useCourseData()

  return isAdminOrOwnerOfCourse(user, courseData)
}
